@import "../../assets/styles/utils";
footer{
  width: 100%;
  @include res(height,520px,620 / 520);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.75);
}
.footer{
  @include res(padding-top,40px,14 / 40);
  @include res(padding-bottom,20px,14 / 20);
  .container{
    @include res(box-sizing,content-box,(1lg:border-box));
    .footer-nav{
      @include res(display,flex,(sm:block));
      justify-content: space-between;
      @include res(margin-top,45px,24 / 45);
      .footer-logo{
        @include res(width,20%,(xs:50%));
        img{
          @include res(width,60%,(xs:60%));
        }
      }
      .footer-text{
        @include res(width,80%,(sm:100%));
        @include res(margin-top,0,(sm:20px));
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        ul{
          @include res(width,31.2%,(xs:50%));
          &:last-child{
            @include res(width,37%,(xs:50%));
          }
          @include res(margin-bottom,0px,(xs:20px));
          &:first-child{
            li{
              &:last-child{
                display: flex;
                @include res(margin-top,20px,10 / 20);
                a{
                  &:hover{
                    padding-left:0;
                  }
                }
                .icon{
                  @include res(width,36px,30 / 36);
                  @include res(height,36px,30 / 36);
                  border: 2px solid #fff;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @include res(margin-right,25px,15 / 25);
                  position: relative;
                  .ewm{
                    @include res(width,150px,80 / 150);
                    @include res(height,150px,80 / 150);
                    position: absolute;
                    @include res(bottom,54px,30 / 54);
                    z-index: 9;
                    display: none;
                    img{
                      width: 100%;
                      height: 100%;
                      display: block;
                      margin: 0 auto;
                    }
                  }
                  &:first-child{
                    .ewm{
                      left: 0;
                    }
                  }
                  &:nth-child(2){
                    .ewm{
                      left:-100%;
                    }
                  }
                  &:last-child{
                    margin-right: 0;
                    .ewm{
                      right: 0;
                    }
                  }
                }
                .iconfont{
                  @include res(font-size,20px,16 / 20);
                  color: #fff;
                }
              }
            }
          }
          h1{
              @include res(font-size,18px,16 / 18);
              color: #fff;
              font-weight: bolder;
              position: relative;
              @include res(margin-bottom,30px,20 / 30);
              &::after{
                  content: '';
                  display: block;
                  @include res(width,38px, 30 / 38);
                  height: 2px;
                  background:#fff;
                  position: absolute;
                  @include res(bottom,-15px, -10 / -15);
                  left:0;
              }
          }
          li{
            margin-top:6px;
            a{
              color:#fff;
              @include res(font-size,14px,13 / 14);
              transition: all 0.3s;
              &:hover{
                color: #00a2dc;
                padding-left:6px;
              }
            }
          }
          &:last-child{
            li{
              display: flex;
              @include res(font-size,14px,13 / 14);
              color:#fff;
              span{
                flex-shrink: 0;
                @include res(width,120px,110 / 120);
              }
            }
          }
        }
      }
    }
  }
}
.copyright{
  text-align: center;
  @include res(font-size,16px,14 / 16);
  @include res(padding-top,12px,15 / 12);
  @include res(padding-bottom,20px,15 / 20);
  font-weight: normal;
  color:#fff;
}
@media (max-width: 767px){
  footer{
    height: 830px;
  }
  .footer .footer-nav .last-ul{
    width: 100%;
  }
  .footer .container .footer-nav .footer-text ul:last-child{
    width: 100%;
  }
}
