@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";
@import "../fonts_n/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

html{
  // font-size: 62.5%;
  overflow-x: hidden;
}
body {
  font-family: 'roboto', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.5;
  position: relative;
  min-height: 100vh;
  color: $color-text;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

//电脑手机适配
.pc-show {
  display: block !important;
}

.pc-show-flex {
  display: flex !important;
}

.mob-show {
  display: none !important;
}

.mob-show-flex {
  display: none !important;
}

//半透明黑色遮罩
.root-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: none;

  &.mask-top {
    z-index: 10;
  }

  &.mask-bottom {
    z-index: 3;
  }
}

//主体内容部分宽度
.container{
  box-sizing: content-box;
  width: 100%;
  max-width: 1800px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);
  &.border-box{
    box-sizing: border-box;
  }
  &.w1200{
    max-width: 1200px;
  }
  &.w1250{
    max-width: 1250px;
  }
  &.w1400{
    max-width: 1400px;
  }
  &.w1500{
    max-width: 1500px;
  }
  &.w1600{
    max-width: 1600px;
  }
  &.w1650{
    max-width: 1650px;
  }
}

.moduleTitle{
  h1{
    @include res(font-size, 40px, 24 / 40);
    color: $color-text;
    font-weight: bolder;
    text-align: center;
  }
  .line{
    width: 40px;
    height: 4px;
    background: #333;
    margin: 15px auto 0;
  }
}

.ny-module-title{
  h1{
    @include res(font-size, 36px, 24 / 36);
    color: $color-text;
    font-weight: bolder;
    text-align: center;
  }
  .line{
    width: 68px;
    height: 4px;
    background: $color-text;
    margin: 8px auto 0;
  }
  .title{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
    .iconfont{
      @include res(font-size,20px, 16 / 20);
      color: $color-text;
      &.icon-yin-left{
        @include res(margin-right,20px, 10 / 20);
      }
      &.icon-yin-right{
        @include res(margin-left,20px, 10 / 20);
      }
    }
    h2{
      @include res(font-size,22px, 16 / 22);
      color: $color-text;
    }
  }
}
