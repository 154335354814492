@import "../../assets/styles/utils";
.header-nav{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: auto;
  background: #fff;
  box-shadow: 0 0 10px #ccc;
  .subnav{
    width: 100vw;
    height: auto;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;
    opacity: 0;
    visibility:hidden;
    transition: 0.5s;
    ul{
      display: flex;
      justify-content: space-between;
      li{
        @include res(padding,15px,6 / 15);
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background:#ededed;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform: scaleY(0);
          transition: all 0.3s;
          transform-origin: bottom center;
        }
        &:hover{
          &::after{
            transform: scaleY(1);
          }
          a{
            p{
              color: #00a2dc;
              font-weight: bolder;
            }
          }
        }
        &.active{
          &::after{
            transform: scaleY(1);
          }
        }
        a{
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          .icon-header{
            img{}
          }
          p{
            @include res(font-size,16px,14 / 16);
            @include res(margin-top,6px,2 / 6);
            color: #333;
          }
        }
      }
    }
  }
}
.header{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &.scroll-header{
    @include res(height,80px,60 / 80);
  }
  .logo{
    a{
      display: flex;
      align-items: center;
    }
    img{
      @include res(width,70%,60 / 70);
    }
  }
  .nav{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    @include res(display,flex, (md:none));
    .parentnav{
      display: flex;
      align-items: center;
      .item{
        height: 100%;
        @include res(padding-left,20px,10 / 20);
        @include res(padding-right,20px,10 / 20);
        display: flex;
        align-items: center;
        &.active{
          background: #ededed;
          .item-link{
            color: #00a2dc;
            font-size: 18px;
          }
        }
        &.active{
          .item-link{
            color: #00a2dc;
            font-weight: bolder;
            border-bottom: 2px solid #00a2dc;
          }
        }
        .item-link{
          font-size: 16px;
          color: #333;
          font-weight: bolder;
          transition: 0.3s;
          border-bottom: 2px solid #fff;
        }
        &:hover{
          background: #ededed;
          .item-link{
            color: #00a2dc;
            font-size: 18px;
            border-bottom: 2px solid #00a2dc;
          }
          .subnav{
            opacity: 1;
            visibility:visible;
          }
        }
        &:nth-child(7){
          display: flex;
          align-items: center;
          margin-right: 0;
          .search-icon{
            display: flex;
            height: 100%;
            align-items: center;
            cursor: pointer;
            position: relative;
            @include res(padding-left,20px, 10 / 20);
            @include res(padding-right,20px, 10 / 20);
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background:#ededed;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              transform: scaleY(0);
              transition: all $anime-duration;
              transform-origin: bottom center;
            }
            &:hover{
              &::after{
                transform: scaleY(1);
              }
              .iconfont{
                color: #00a2dc;
              }
            }
            .search{
              @include res(font-size,26px,20 / 26);
            }
            &.search-show{
              &::after{
                transform: scaleY(1);
              }
              .iconfont{
                color: #00a2dc;
              }
              .icon-guanbi{
                display: inline;
              }
              .icon-sousuo{
                display: none;
              }
            }
            .icon-guanbi{
              display: none;
            }
          }
          &:hover{
            background: none;
          }
        }
        &:last-child{
          .language{
            display: flex;
            align-items: center;
            justify-content: space-between;
            // @include res(margin-left,20px,6 / 20);
            a{
              padding-bottom: 0;
              border-bottom: none;
              font-weight: normal;
              transition: 0.3s;
              font-size:20px;
              transition: 0.3s;
            }
          }
          &:hover{
            .language{
              a{
                color:#00a2dc;
              }
            }
          }
        }
      }
    }
  }
  .header-search{
      &.show{
          @include res(top,70px,60 / 70);
      }
  }
  .mob-menu-icon{
    height: 100%;
    position: absolute;
    top: 0;
    right: 30px;
    align-items: center;
    @include res(display, none, (md:flex));
    .menu-text{
      color: #fff;
      text-transform: uppercase;
      font-weight: lighter;
      @include res(margin-right,20px, 15 / 20);
      a{
        font-size: 18px;
        color:#00a2dc;
      }
    }
    .hamburger>div,.hamburger>div:after,.hamburger>div:before{
      background-color: #00a2dc;
    }
  }
}
.header-search{
  position: fixed;
  z-index: 99;
  width: 100%;
  background:#ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  transition: all .4s;
  top: -20%;
  @include res(padding-top,50px, 10 / 50);
  @include res(padding-bottom,50px, 10 / 50);
  &.show{
    @include res(top,$header-height-base,$header-height-ratio);
  }
  .search-form{
    width: 100%;
    max-width: 500px;
    form{
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
    input{
      width: 80%;
      border: none;
      padding: 0 10px;
      outline: none;
      @include res(height,50px, 30 / 50);
    }
    button{
      width: 20%;
      border: none;
      outline: none;
      background: #d3d3d3;
      cursor: pointer;
      @include res(font-size,$text-size-md-base,$text-size-md-ratio);
    }
  }
}
.mob-nav-mask{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.6);
  z-index: 1011;
  display: none;
}
.mob-nav {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100%;
  z-index: 1012;
  background:#555;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(0);
  transition: all $anime-duration;
  @include res(width, 50%, 80 / 50);
  &.show {
    transform: translateX(-100%);
  }
  .close-mob-nav {
    padding-bottom: 20px;
    text-align: right;
  }
  .menu {
    ul {
      li {
        .item-link{
          position: relative;
          display: block;
          padding: 10px 0;
          border-bottom: 1px solid rgba(255, 255, 255, .3);
        }
        &:nth-child(3){
          .item-link{
            position: relative;
            &::after {
              content:"\e6b9";
              font-family: 'iconfont';
              position: absolute;
              top: 30%;
              right: 10px;
              transform:rotate(-90deg);
              transition:all 0.3s;
              font-size:16px;
              color: rgba(255, 255, 255, .8);
            }
          }
          .subnav-mob{
            display: none;
              padding-left: 10px;
            .subnav-item{
              border-bottom: 1px solid rgba(255, 255, 255, .3);
              padding: 10px 0;
              &:last-child{
                border-bottom: none;
              }
            }
          }
        }
        &:last-child{
          a{
            display: flex;
            align-items: center;
            .search{
              font-size: 20px;
            }
            input{
              background: none;
              border: none;
              outline: none;
              margin-left: 10px;
              color: #fff;
            }
          }
        }
        &.active{
          border-bottom: 1px solid rgba(255, 255, 255, .3);
          .item-link{
            &::after {
              transform:rotate(-180deg);
            }
          }
        }
      }
    }
  }
}
