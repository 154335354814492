@font-face {
  font-family: "iconfont"; /* Project id 4446706 */
  src: url('iconfont.woff2?t=1709704928768') format('woff2'),
       url('iconfont.woff?t=1709704928768') format('woff'),
       url('iconfont.ttf?t=1709704928768') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vk:before {
  content: "\e660";
}

.icon-jiantou-xiangzuo:before {
  content: "\e672";
}

.icon-jiantou_liebiaoxiangyou_o:before {
  content: "\e7a3";
}

.icon-icon:before {
  content: "\e602";
}

.icon-zongbu:before {
  content: "\e80f";
}

.icon-zongbu1:before {
  content: "\e63c";
}

.icon-gongchang:before {
  content: "\e98e";
}

